import React, { useState, useEffect, useRef } from "react";
import {
  Link,
} from "react-router-dom";

// import heroImage from '../../assets/main-hero.webp'; // 이미지를 불러옵니다
import { IconButton, Typography, iconButton } from "@material-tailwind/react";
import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  MotionValue
} from "framer-motion";

import { Canvas, extend, useThree, useFrame } from "@react-three/fiber";
import { Splat, Float, CameraControls, StatsGl } from "@react-three/drei";

const Main = () => {
  const [colorMode, setColorMode] = useState(0);
  const [bgColor1, setBgColor1] = useState('bg-white');
  const [bgColor2, setBgColor2] = useState('bg-gray-400');
  const [titleColor1, setTitleColor1] = useState('black');
  const [skewy2, setSkewy2] = useState(0);
  const [opacity21, setOpacity21] = useState('0');
  const [opacity22, setOpacity22] = useState('0');
  const [opacity23, setOpacity23] = useState('0');
  const [opacity24, setOpacity24] = useState('0');
  const [color2, setColor2] = useState('white');

  const [opacity3, setOpacity3] = useState('0');

  const [opacity5, setOpacity5] = useState('0');

  const [opacity61, setOpacity61] = useState('0');
  const [opacity62, setOpacity62] = useState('0');
  const [opacity63, setOpacity63] = useState('0');

  const [opacity71, setOpacity71] = useState('0');
  const [opacity72, setOpacity72] = useState('0');
  const [opacity73, setOpacity73] = useState('0');

  const [rotate7, setRotate7] = useState('180');

  const { scrollYProgress } = useScroll();
  const height = window.innerHeight;
  const ratio = height / 996

  const controlsRef = useRef();
  useEffect(() => {
    const handleScroll = () => {
      // Set background to black if scrolled more than 50px, otherwise white
      let colorMode = 0;
      // console.log(scrollYProgress.current)
      if (window.innerWidth > 500) {
        colorMode = window.scrollY > 50 * ratio ? 1 : 0;
        colorMode = window.scrollY > 600 * ratio ? 2 : colorMode;
        colorMode = window.scrollY > 1500 * ratio ? 3 : colorMode;
        colorMode = window.scrollY > 2200 * ratio ? 4 : colorMode;
        colorMode = window.scrollY > 3500 * ratio ? 5 : colorMode;
        colorMode = window.scrollY > 4200 * ratio ? 6 : colorMode;
        colorMode = window.scrollY > 5000 * ratio ? 6.1 : colorMode;
        colorMode = window.scrollY > 5850 * ratio ? 6.2 : colorMode;
        colorMode = window.scrollY > 6700 * ratio ? 6.3 : colorMode;
        colorMode = window.scrollY > 7600 * ratio ? 7 : colorMode;
        colorMode = window.scrollY > 8600 * ratio ? 8 : colorMode;
      } else{
        colorMode = scrollYProgress.current > 0.005 ? 1 : 0;
        colorMode = scrollYProgress.current > 0.06 ? 2 : colorMode;
        colorMode = scrollYProgress.current > 0.13 ? 3 : colorMode;
        colorMode = scrollYProgress.current > 0.21 ? 4 : colorMode;
        colorMode = scrollYProgress.current > 0.28 ? 5 : colorMode;
        colorMode = scrollYProgress.current > 0.39 ? 6 : colorMode;
        colorMode = scrollYProgress.current > 0.46 ? 6.1 : colorMode;
        colorMode = scrollYProgress.current > 0.58 ? 6.2 : colorMode;
        colorMode = scrollYProgress.current > 0.70 ? 6.3 : colorMode;
        colorMode = scrollYProgress.current > 0.88 ? 7 : colorMode;
        colorMode = scrollYProgress.current > 0.95 ? 8 : colorMode;
      }


      if (colorMode === 0) {
        setBgColor1('bg-white');
        setBgColor2('bg-gray-400');
        setTitleColor1('black');
      } else if (colorMode === 1) {
        setBgColor1('bg-black');
        setBgColor2('bg-black');
        setTitleColor1('white');
        setSkewy2('0');
      } else if (colorMode === 2) {
        setBgColor1('bg-black');
        setBgColor2('bg-gray-100');
        setTitleColor1('white');
        setSkewy2('12');
        setOpacity3(0);
        setColor2('white');
      } else if (colorMode === 3) {
        setBgColor1("bg-gray-100");
        setBgColor2('bg-gray-100');
        setTitleColor1('black');
        setOpacity3(100);
        setColor2('black');
      } else if (colorMode === 4) {
        setBgColor1("bg-black bg-opacity-70");
        setBgColor2('bg-gray-100');
        setTitleColor1('white');
        setOpacity3(0);
        setColor2('black');
      } else if (colorMode === 5) {
        setBgColor1("bg-yellow-300");
        setBgColor2('bg-gray-100');
        setTitleColor1('black');
        setColor2('white');
      } else if (colorMode === 6) {
        setBgColor1("bg-green-300");
        setBgColor2('bg-gray-100');
        setTitleColor1('black');
        setColor2('black');
      } else if (colorMode === 6.1) {
        setBgColor1("bg-blue-300");
        setBgColor2('bg-gray-100');
        setTitleColor1('black');
        setColor2('black');
      } else if (colorMode === 6.2) {
        setBgColor1("bg-black");
        setBgColor2('bg-gray-100');
        setTitleColor1('white');
        setColor2('white');
      } else if (colorMode === 6.3) {
        setBgColor1("bg-lime-300");
        setBgColor2('bg-gray-100');
        setTitleColor1('black');
        setColor2('black');
      } else if (colorMode === 7) {
        setBgColor1("bg-black");
        setBgColor2('bg-black');
        setTitleColor1('white');
        setColor2('white');
      } else if (colorMode === 8) {
        setBgColor1("bg-white");
        setBgColor2('bg-gray-400');
        setTitleColor1('black');
        setColor2('black');
      }

      // 구전에서~~홀로그램으로
      if (window.innerWidth > 500) {
        if (window.scrollY > 1000 * ratio) {
          setOpacity21(100);
          setOpacity22(100);
          setOpacity23(100);
          setOpacity24(100);
        } else if (window.scrollY > 900 * ratio) {
          setOpacity21(100);
          setOpacity22(100);
          setOpacity23(100);
          setOpacity24(0);
        } else if (window.scrollY > 800 * ratio) {
          setOpacity21(100);
          setOpacity22(100);
          setOpacity23(0);
          setOpacity24(0);
        } else if (window.scrollY > 700 * ratio) {
          setOpacity21(100);
          setOpacity22(0);
          setOpacity23(0);
          setOpacity24(0);
        } else {
          setOpacity21(0);
          setOpacity22(0);
          setOpacity23(0);
          setOpacity24(0);
        }
  
        // 삭제 안됨
        if (window.scrollY > 3500 * ratio) {
          setOpacity5(100);
        } else {
          setOpacity5(0);
        }
  
        // 완벽한 보안
        if (window.scrollY > 4800 * ratio) {
          setOpacity61(100);
          setOpacity62(100);
          setOpacity63(100);
        } else if (window.scrollY > 4700 * ratio) {
          setOpacity61(100);
          setOpacity62(100);
          setOpacity63(0);
        } else if (window.scrollY > 4600 * ratio) {
          setOpacity61(100);
          setOpacity62(0);
          setOpacity63(0);
        } else {
          setOpacity61(0);
          setOpacity62(0);
          setOpacity63(0);
        }
  
        // 합리적 가격
        if (window.scrollY > 8000 * ratio) {
          setOpacity71(100);
          setOpacity72(100);
          setOpacity73(100);
        } else if (window.scrollY > 7900 * ratio) {
          setOpacity71(100);
          setOpacity72(100);
          setOpacity73(0);
        } else if (window.scrollY > 7800 * ratio) {
          setOpacity71(100);
          setOpacity72(0);
          setOpacity73(0);
          setRotate7(0);
        } else {
          setOpacity71(0);
          setOpacity72(0);
          setOpacity73(0);
          setRotate7(180);
        }
      } else {
        if (scrollYProgress.current> 0.078) {
          setOpacity21(100);
          setOpacity22(100);
          setOpacity23(100);
          setOpacity24(100);
        } else if (scrollYProgress.current> 0.068) {
          setOpacity21(100);
          setOpacity22(100);
          setOpacity23(100);
          setOpacity24(0);
        } else if (scrollYProgress.current> 0.058) {
          setOpacity21(100);
          setOpacity22(100);
          setOpacity23(0);
          setOpacity24(0);
        } else if (scrollYProgress.current> 0.048) {
          setOpacity21(100);
          setOpacity22(0);
          setOpacity23(0);
          setOpacity24(0);
        } else {
          setOpacity21(0);
          setOpacity22(0);
          setOpacity23(0);
          setOpacity24(0);
        }
  
        // 삭제 안됨
        if (scrollYProgress.current> 0.30) {
          setOpacity5(100);
        } else {
          setOpacity5(0);
        }
  
        // 완벽한 보안
        if (scrollYProgress.current> 0.39) {
          setOpacity61(100);
          setOpacity62(100);
          setOpacity63(100);
        } else if (scrollYProgress.current> 0.375) {
          setOpacity61(100);
          setOpacity62(100);
          setOpacity63(0);
        } else if (scrollYProgress.current> 0.36) {
          setOpacity61(100);
          setOpacity62(0);
          setOpacity63(0);
        } else {
          setOpacity61(0);
          setOpacity62(0);
          setOpacity63(0);
        }
  
        // 합리적 가격
        if (scrollYProgress.current> 0.90) {
          setOpacity71(100);
          setOpacity72(100);
          setOpacity73(100);
        } else if (scrollYProgress.current> 0.89) {
          setOpacity71(100);
          setOpacity72(100);
          setOpacity73(0);
        } else if (scrollYProgress.current> 0.88) {
          setOpacity71(100);
          setOpacity72(0);
          setOpacity73(0);
          setRotate7(0);
        } else {
          setOpacity71(0);
          setOpacity72(0);
          setOpacity73(0);
          setRotate7(180);
        }
      }
      
    };
    // Add scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener('scroll', handleScroll);
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <>
      <div className={`fixed opacity-90 ${bgColor2} bg-opacity-10 h-[60px] top-0 left-0 w-full flex justify-center z-[998]`}>
        <Link to={"/"}>
          <img
            alt="evova"
            className="w-[60px]"
            src={`${process.env.PUBLIC_URL}/icons/logo-metown_icon_CMYK.webp`}
          />
        </Link>
      </div>

      <div className="fixed top-0 right-5 z-[998]">
        <Link to={"/search"}>
          <img
            alt="search"
            className="h-[30px] mt-[15px]"
            src={`${process.env.PUBLIC_URL}/icons/search.svg`}
          />
        </Link>
      </div>

      <div className={`relative min-h-[${height}px] w-full justify-items-center justify-center`}>
        {/* <div className={`relative min-h-screen w-full `}> */}
        <div className={`absolute inset-0 w-full ${bgColor1} transition-all duration-1000`} />
        {/* Section1 */}
        <div className={`grid `} style={{height: `${height}px`}}>
          <div className="container relative z-10 my-auto mx-auto grid place-items-center text-center">
            <Typography variant="h1" color={`${titleColor1}`} className='duration-1000' style={{ fontFamily: 'GmarketSansMedium' }}>
              인생백컷
            </Typography>
            <Typography variant="h3" color="gray" className='scale-100 opacity-50 px-4' style={{ fontFamily: 'GmarketSansMedium' }}>
              RememberForever
            </Typography>
            <Typography
              variant="lead"
              color="white"
              className="mt-12 mb-12 w-full md:max-w-full lg:max-w-3xl px-4"
              style={{ fontFamily: 'GmarketSansMedium' }}
            >
              {/* 순간의 소중함은 추억이 되기 전까지는<br/> 절대 알 수 없고,<br /> */}
              {/* 인생은 단 한번의 추억여행이다. */}
              당신의 리즈를 100대의 카메라로 기록하세요.<br />
              신개념 3D 홀로그램 사진, 인생백컷.
            </Typography>
            <div className="gap-8 flex">
              {/* <IconButton variant="text" color="white" size="sm">
                <i className="fa-brands fa-twitter text-base z-[999]" />
              </IconButton>
              <IconButton variant="text" color="white" size="sm">
                <i className="fa-brands fa-facebook text-base" />
              </IconButton> */}
              <Link to='https://www.instagram.com/rememberforever.official?igsh=MXBkazlsbXIxZWh6cQ%3D%3D&utm_source=qr' target="_blank">
                <IconButton variant="text" color="white" size="sm">
                  <i className="fa-brands fa-instagram text-base" />
                </IconButton></Link>
            </div>
          </div>
        </div>
        {/* Section2 */}
        <div className={`grid px-8`} style={{height: `${height}px`}}>
          <div className="container relative z-10 my-auto mx-auto grid place-items-center text-center">
            <Typography variant="h1" color={`${titleColor1}`} style={{ fontFamily: 'GmarketSansMedium' }}>
              특별하게
            </Typography>
            <Typography variant="h1" color="blue-gray" textGradient className={`scale-100 skew-y-${skewy2} duration-1000`} style={{ fontFamily: 'GmarketSansMedium' }}>
              추억하세요
            </Typography>
            <Typography
              variant="lead"
              color={color2}
              className={`mt-12 w-full md:max-w-full lg:max-w-3xl opacity-${opacity21} duration-1000`}
              style={{ fontFamily: 'GmarketSansMedium' }}
            >
              말보다는 글로,
            </Typography>
            <Typography
              variant="lead"
              color={color2}
              className={`w-full md:max-w-full lg:max-w-3xl opacity-${opacity22} duration-1000`}
              style={{ fontFamily: 'GmarketSansMedium' }}
            >
              글보다는 그림으로,
            </Typography>
            <Typography
              variant="lead"
              color={color2}
              className={`w-full md:max-w-full lg:max-w-3xl opacity-${opacity23} duration-1000`}
              style={{ fontFamily: 'GmarketSansMedium' }}
            >
              그림보다는 사진으로,
            </Typography>
            <Typography
              variant="lead"
              color={color2}
              className={`w-full md:max-w-full lg:max-w-3xl opacity-${opacity24} duration-1000`}
              style={{ fontFamily: 'GmarketSansMedium' }}
            >
              사진보다는 <b>홀로그램</b>으로.
            </Typography>

          </div>
        </div>
        {/* Section3 */}
        <div className={`grid px-8`} style={{height: `${height}px`}}>
          <div className="container relative my-auto mx-auto grid place-items-center text-center">
            <Typography variant="h1" color={color2} className={`duration-1000 z-10`} style={{ fontFamily: 'GmarketSansMedium' }}>
              입체적으로
            </Typography>
            <Typography variant="h1" color="gray" className='scale-100 opacity-50 z-30 mb-24' style={{ fontFamily: 'GmarketSansMedium' }}>
              3D 홀로그램
            </Typography>

            <div className='z-30'>
              <Typography
                variant="small"
                color='black'
                className={`mt-0 mb-12 w-full md:max-w-full lg:max-w-3xl duration-1000`}
                style={{ fontFamily: 'GmarketSansMedium' }}
              >
                <br />홀로그램을 돌려보고 확대해보고 움직여보세요.
              </Typography>
            </div>

          </div>
          <div className={`w-8/12 md:w-6/12 z-20 center mx-auto place-items-center text-center opacity-${opacity3} duration-1000`} style={{marginTop: `-${height*0.6}px`, height: `${height*0.7}px`}}>
            <Canvas
              // dpr={1.5}
              gl={{ antialias: false }}
              camera={{ position: [0, 2, 9], fov: 35 }}
            >
              <CameraControls
                ref={controlsRef}
                truckSpeed={0}
                maxDistance={11}
                minDistance={7}
                maxPolarAngle={(3.141592 / 3) * 1.8}
                minPolarAngle={(3.141592 / 3) * 0.5}
                // minAzimuthAngle={0} // Min angle for horizontal rotation
                boundaryEnclosesCamera={true}
                boundaryFriction={100}
              />
              <Splat
                toneMapped
                alphaTest={0}
                // src={"https://firebasestorage.googleapis.com/v0/b/metowndb.appspot.com/o/2402_Landing_Splat_Examples%2Foffwhite2%2Foffwhite4.splat?alt=media&token=fa267c88-fe38-490c-b947-317d2a31b39f"}
                src={"https://firebasestorage.googleapis.com/v0/b/metowndb.appspot.com/o/remeberforever%2Fpoint_cloud%20(1).splat?alt=media&token=dbbb2b5d-0837-4ea4-875b-50af0d89ccaf"}
                // position={[0, 0.5, 0]}
                position={[-0.1, -1.5, -0.3]}
                scale={1.5}
              />
            </Canvas>
          </div>
        </div>
        {/* Section4 */}
        <div className={`grid min-h-[${height}px] bg-cover bg-fixed h-full justify-center`} style={{
          backgroundImage: `url("/image/scanner2.jpg")`,
          backgroundPosition: `center`,
          backgroundAttachment: `scroll`,
          width: `100vw`,
          height: `${height}px`
        }}>
          <div className="container relative z-10 my-auto mx-auto grid place-items-center text-center" >
            <Typography variant="h1" color={`${titleColor1}`} className='duration-1000 px-8' style={{ fontFamily: 'GmarketSansMedium' }}>
              가장 가치있는 기록
            </Typography>
            <Typography variant="h3" color="gray" className='scale-100 opacity-50 px-4' style={{ fontFamily: 'GmarketSansMedium' }} >
              RememberForever
            </Typography>
            <Typography
              variant="lead"
              color="white"
              className="mt-12 mb-2 w-full md:max-w-full lg:max-w-3xl"
              style={{ fontFamily: 'GmarketSansMedium' }}
            >
              실제 촬영장소 모습입니다.
            </Typography>
            <Typography
              variant="lead"
              color="white"
              className="mb-12 w-full md:max-w-full lg:max-w-3xl px-8"
              style={{ fontFamily: 'GmarketSansMedium' }}
            >
              {/* 100여대의 카메라로 <br/>가장 빛나는 순간을 기록하세요. */}
              100여대의 카메라와 함께하는 진귀한 경험.
            </Typography>
            <div className="gap-8 flex">
              <Link to="https://naver.me/FcuG3dm4">
              <IconButton variant="text" color="white" size="sm">
                <i className="	fas fa-map-marked text-base z-[999]" />
              </IconButton>
              </Link>
            </div>
          </div>
        </div>
        {/* Section5 */}
        <div className={`grid px-8`}  style={{height: `${height}px`}}>
          <div className="container relative z-10 my-auto mx-auto grid place-items-center text-center">
            <Typography variant="h1" color={`${titleColor1}`} className='duration-1000' style={{ fontFamily: 'GmarketSansMedium' }}>
              구성품
            </Typography>
            <Typography variant="h1" color="gray" className='scale-100 opacity-50' style={{ fontFamily: 'GmarketSansMedium' }}>
              Set
            </Typography>
            <Typography
              variant="lead"
              color={titleColor1}
              className="mt-12 mb-0 w-full md:max-w-full lg:max-w-3xl"
              style={{ fontFamily: 'GmarketSansMedium' }}
            >
              <b>1.</b> QR 코드가 있는 사진
            </Typography>
            <Typography
              variant="lead"
              color={titleColor1}
              className="mt-2 mb-2 w-full md:max-w-full lg:max-w-3xl"
              style={{ fontFamily: 'GmarketSansMedium' }}
            >
              <b>2.</b> 웹으로 볼 수 있는 홀로그램
            </Typography>
            <Link className={`w-full md:w-1/3 center  opacity-${opacity5} duration-1000`} to='/show/yoseblight.splat/e25a1e01-ce3d-4ba1-a324-d7e7d6e07085/1/270/255/241/118' target="_blank">
              <img src='/image/pic_sample.png'></img>
              <Typography
                variant="small"
                color="gray"
                className="mt-0 w-full md:max-w-full lg:max-w-3xl"
                style={{ fontFamily: 'GmarketSansMedium' }}
              >
                클릭하여 큐알코드로 이동
              </Typography>
            </Link>

          </div>
        </div>
        {/* Section6 */}
        <div className={`grid px-8`}  style={{height: `${height}px`}}>
          <div className="container relative z-10 my-auto mx-auto grid place-items-center text-center">
            <Typography variant="h1" color={`${titleColor1}`} className='duration-1000' style={{ fontFamily: 'GmarketSansMedium' }}>
              진행
            </Typography>
            <Typography variant="h1" color="gray" className='scale-100 opacity-50' style={{ fontFamily: 'GmarketSansMedium' }}>
              Process
            </Typography>
            <Typography
              variant="lead"
              color={titleColor1}
              className={`mt-12 mb-2 w-full md:max-w-full lg:max-w-3xl opacity-${opacity61} duration-1000`}
              style={{ fontFamily: 'GmarketSansMedium' }}
            >
              <b>0.</b> 설명 및 안내를 해드리고, 촬영을 위한 준비를 합니다. (10분)
            </Typography>
            <Typography
              variant="lead"
              color={titleColor1}
              className={`mb-2 w-full md:max-w-full lg:max-w-3xl opacity-${opacity62} duration-1000`}
              style={{ fontFamily: 'GmarketSansMedium' }}
            >
              <b>1.</b> 촬영을 진행합니다. (10분)
            </Typography>
            <Typography
              variant="lead"
              color={titleColor1}
              className={`mb-2 w-full md:max-w-full lg:max-w-3xl opacity-${opacity63} duration-1000`}
              style={{ fontFamily: 'GmarketSansMedium' }}
            >
              <b>2.</b> 홀로그램으로 만들 사진을 셀렉하고, 컨셉 및 색상을 정합니다.
            </Typography>
            <Typography
              variant="lead"
              color={titleColor1}
              className={`mb-12 w-full md:max-w-full lg:max-w-3xl opacity-${opacity63} duration-1000`}
              style={{ fontFamily: 'GmarketSansMedium' }}
            >
              <b>3.</b> 보정한 사진과 홀로그램 링크를 전달드립니다. (2시간)
            </Typography>
          </div>
        </div>
        {/* Section6.1 */}
        <div className={`grid min-h-[${height}px] px-8`} style={{heightMin: `${height}px`}}>
          <div className="container relative z-10 my-auto mx-auto grid place-items-center text-center">
            <Typography variant="h1" color={`${titleColor1}`} className='duration-1000' style={{ fontFamily: 'GmarketSansMedium' }}>
              이렇게 찍어보세요
            </Typography>
            <Typography variant="h1" color="gray" className='scale-100 opacity-50' style={{ fontFamily: 'GmarketSansMedium' }}>
              Concepts
            </Typography>
            <Typography
              variant="lead"
              color={titleColor1}
              className={`mt-12 mb-0 w-full md:max-w-full lg:max-w-3xl opacity-${opacity61} duration-1000`}
              style={{ fontFamily: 'GmarketSansMedium' }}
            >
              다양한 배경
            </Typography>
            <Typography
              variant="small"
              color={"gray"}
              className={`mt-0 mb-2 w-full md:max-w-full lg:max-w-3xl opacity-${opacity61} duration-1000`}
              style={{ fontFamily: 'GmarketSansMedium' }}
            >
              클릭하면 3D로 이동
            </Typography>
            <div className="columns-1 md:columns-3 w-8/12 md:w-8/12">
              <Link className={`w-1/6 center opacity-${opacity5} duration-1000`} to='/show/subin_bg.splat/823b7843-520f-4868-b4e3-f3b1206fb85a/0.6/210/0/0/0
              ' target="_blank">
                <img src='/image/subin6.jpg' className="-rotate-0"></img>
                <Typography
                  variant="small"
                  color="gray"
                  className="mt-0 mb-2 w-full md:max-w-full lg:max-w-3xl"
                  style={{ fontFamily: 'GmarketSansMedium' }}
                >
                  STUDIO
                </Typography>
              </Link>
              <Link className={`w-1/6 center opacity-${opacity5} duration-1000`} to='/show/yejung.splat/823b7843-520f-4868-b4e3-f3b1206fb85a/0.6/90/241/218/200' target="_blank">
                <img src='/image/yejung.jpg'></img>
                <Typography
                  variant="small"
                  color="gray"
                  className="mt-0 mb-2 w-full md:max-w-full lg:max-w-3xl"
                  style={{ fontFamily: 'GmarketSansMedium' }}
                >
                  LIGHT COLOR
                </Typography>
              </Link>

              <Link className={`w-1/6 center opacity-${opacity5} duration-1000`} to='/show/yosebheap3.splat/e156d53e-d838-47b5-8ccd-4d936e527545/0.6/70/3/5/50' target="_blank">
                <img src='/image/yosebheap.jpg'></img>
                <Typography
                  variant="small"
                  color="gray"
                  className="mt-0 mb-2 w-full md:max-w-full lg:max-w-3xl"
                  style={{ fontFamily: 'GmarketSansMedium' }}
                >
                  DARK COLOR
                </Typography>
              </Link>
            </div>
            <Typography
              variant="lead"
              color={titleColor1}
              className={`mt-4 mb-2 w-full md:max-w-full lg:max-w-3xl opacity-${opacity63} duration-1000`}
              style={{ fontFamily: 'GmarketSansMedium' }}
            >
              혼자서, 친구와, 연인과, 가족과
            </Typography>
            <div className="columns-1 md:columns-3 w-8/12 md:w-8/12">
              <Link className={`w-1/6 center opacity-${opacity5} duration-1000`} to='/show/subin_.splat/01ae27ac-4a3d-43d2-beca-ac8ad4dc2b6e/0.7/210/0/0/0' target="_blank">
                <img src='/image/subin.jpg'></img>
                <Typography
                  variant="small"
                  color="gray"
                  className="mt-0 mb-2 w-full md:max-w-full lg:max-w-3xl"
                  style={{ fontFamily: 'GmarketSansMedium' }}
                >
                  혼자서,
                </Typography>
              </Link>
              <Link className={`w-1/6 center opacity-${opacity5} duration-1000`} to='/show/couplerembg.splat/5c09ba1a-c03a-44a6-bde3-9c274de85581/0.6/80/25/18/11' target="_blank">
                <img src='/image/couplebg.jpg'></img>
                <Typography
                  variant="small"
                  color="gray"
                  className="mt-0 mb-2 w-full md:max-w-full lg:max-w-3xl"
                  style={{ fontFamily: 'GmarketSansMedium' }}
                >
                  연인과,
                </Typography>
              </Link>

              <Link className={`w-1/6 center opacity-${opacity5} duration-1000`} to='/show/together.splat/7020a9e5-b15a-4af6-ad7e-0d28f0c431c2/0.7/180/0/0/0' target="_blank">
                <img src='/image/togetherbg.jpg'></img>
                <Typography
                  variant="small"
                  color="gray"
                  className="mt-0 mb-2 w-full md:max-w-full lg:max-w-3xl"
                  style={{ fontFamily: 'GmarketSansMedium' }}
                >
                  친구와
                </Typography>
              </Link>
            </div>
            <Typography
              variant="lead"
              color={titleColor1}
              className={`mt-4 mb-2 w-full md:max-w-full lg:max-w-3xl opacity-${opacity62} duration-1000`}
              style={{ fontFamily: 'GmarketSansMedium' }}
            >
              색다르게
            </Typography>
            <div className="columns-1 md:columns-3 w-8/12 md:w-8/12">
              <Link className={`w-1/6 center opacity-${opacity5} duration-1000`} to='/show/kc.splat/84a9bb57-62f5-4b29-89bc-cbe349458c81/1/290/34/41/55' target="_blank">
                <img src='/image/kcbg.jpg'></img>
                <Typography
                  variant="small"
                  color="gray"
                  className="mt-0 mb-2 w-full md:max-w-full lg:max-w-3xl"
                  style={{ fontFamily: 'GmarketSansMedium' }}
                >
                  바디프로필
                </Typography>
              </Link>
              <Link className={`w-1/6 center opacity-${opacity5} duration-1000`} to='/show/sp1.splat/5fe2ff81-afde-4695-9161-8b760693d709/1/300/118/241/255' target="_blank">
                <img src='/image/sp1bg.jpg'></img>
                <Typography
                  variant="small"
                  color="gray"
                  className="mt-0 mb-2 w-full md:max-w-full lg:max-w-3xl"
                  style={{ fontFamily: 'GmarketSansMedium' }}
                >
                  순간포착!
                </Typography>
              </Link>

              <Link className={`w-1/6 center opacity-${opacity5} duration-1000`} to='/show/subin_.splat/01ae27ac-4a3d-43d2-beca-ac8ad4dc2b6e/0.7/210/0/0/0' target="_blank">
                <img src='/image/flowerbg.jpg'></img>
                <Typography
                  variant="small"
                  color="gray"
                  className="mt-0 mb-2 w-full md:max-w-full lg:max-w-3xl"
                  style={{ fontFamily: 'GmarketSansMedium' }}
                >
                  신선하게?
                </Typography>
              </Link>
            </div>
            <Typography
              variant="lead"
              color={titleColor1}
              className={`mt-4 mb-2 w-full md:max-w-full lg:max-w-3xl opacity-${opacity63} duration-1000`}
              style={{ fontFamily: 'GmarketSansMedium' }}
            >
              3D 스튜디오와 함께
            </Typography>
            <div className="columns-1 md:columns-2 w-8/12 md:w-5/12">
              <Link className={`w-1/6 center opacity-${opacity5} duration-1000`} to='/show/coupleudbg.splat/c466eb29-0987-49fa-a6fc-9d3ccfe084d3/0.6/80/0/0/0' target="_blank">
                <img src='/image/couplebg.jpg'></img>
                <Typography
                  variant="small"
                  color="gray"
                  className="mt-0 mb-2 w-full md:max-w-full lg:max-w-3xl"
                  style={{ fontFamily: 'GmarketSansMedium' }}
                >
                  클릭하여 보기
                </Typography>
              </Link>

              <Link className={`w-1/6 center opacity-${opacity5} duration-1000`} to='/show/yoseblightbg.splat/e4bb1196-34db-484c-9aaa-60c045903fb6/1/270/255/255/255' target="_blank">
                <img src='/image/yosebbg.jpg'></img>
                <Typography
                  variant="small"
                  color="gray"
                  className="mt-0 mb-2 w-full md:max-w-full lg:max-w-3xl"
                  style={{ fontFamily: 'GmarketSansMedium' }}
                >
                  클릭하여 보기
                </Typography>
              </Link>
            </div>

          </div>
        </div>
        {/* Section7 */}
        <div className={`grid min-h-[${height}px] px-8`} style={{height: `${height}px`}}>
          <div className="container relative z-10 my-auto mx-auto grid place-items-center text-center">
            <Typography variant="h1" color={`${titleColor1}`} className='duration-1000' style={{ fontFamily: 'GmarketSansMedium' }}>
              합리적인 가격
            </Typography>
            <Typography variant="h1" color="gray" className={`rotate-${rotate7} opacity-50 duration-1000`} style={{ fontFamily: 'GmarketSansMedium' }} >
              \9,900
            </Typography>
            <Typography
              variant="lead"
              color={titleColor1}
              className={`mt-12 mb-2 w-full md:max-w-full lg:max-w-3xl opacity-${opacity71} duration-1000`}
              style={{ fontFamily: 'GmarketSansMedium' }}
            >
              홍익대학교 도보 10분거리
            </Typography>
            <Typography
              variant="lead"
              color={titleColor1}
              className={`mb-2 w-full md:max-w-full lg:max-w-3xl opacity-${opacity72} duration-1000`}
              style={{ fontFamily: 'GmarketSansMedium' }}
            >
              편안한 촬영분위기
            </Typography>
            <Typography
              variant="lead"
              color={titleColor1}
              className={`mb-12 w-full md:max-w-full lg:max-w-3xl opacity-${opacity73} duration-1000`}
              style={{ fontFamily: 'GmarketSansMedium' }}
            >
              주차는 미리 문의바랍니다.
            </Typography>
            <div className="gap-8 flex">
              <Link to='https://naver.me/FcuG3dm4' target="_blank">
                <IconButton variant="text" color="white" size="sm">
                  <i className="	fas fa-map-marked text-base z-[999]" />
                </IconButton>
                <Typography
                  variant="small"
                  color="white"
                  className={`mb-12 w-full md:max-w-full lg:max-w-3xl opacity-${opacity73} duration-1000`}
                  style={{ fontFamily: 'GmarketSansMedium' }}
                >
                  와우산로 26길 58, 샬롬하우스 101호
                </Typography>
              </Link>
            </div>
          </div>
        </div>
        {/* Section8 */}
        <div className={`grid min-h-[${height}px] px-8`} style={{height: `${height}px`}}>
          <div className="container relative z-10 my-auto mx-auto grid place-items-center text-center">
            <Typography variant="h1" color={`${titleColor1}`} className='duration-1000' style={{ fontFamily: 'GmarketSansMedium' }}>
              예약
            </Typography>
            <Typography variant="h1" color="gray" className='scale-100 opacity-50' style={{ fontFamily: 'GmarketSansMedium' }}>
              Reservation
            </Typography>
            <Typography
              variant="lead"
              color={titleColor1}
              className={`mt-12 mb-2 w-full md:max-w-full lg:max-w-3xl opacity-${opacity71} duration-1000`}
              style={{ fontFamily: 'GmarketSansMedium' }}
            >
              1인 환영
            </Typography>
            <Typography
              variant="lead"
              color={titleColor1}
              className={`mb-2 w-full md:max-w-full lg:max-w-3xl opacity-${opacity72} duration-1000`}
              style={{ fontFamily: 'GmarketSansMedium' }}
            >
              2인 이상 환영
            </Typography>
            <Typography
              variant="lead"
              color={titleColor1}
              className={`mb-12 w-full md:max-w-full lg:max-w-3xl opacity-${opacity73} duration-1000`}
              style={{ fontFamily: 'GmarketSansMedium' }}
            >
              강아지 환영
            </Typography>
            <Typography
              variant="lead"
              color={titleColor1}
              className={`mb-12 w-full md:max-w-full lg:max-w-3xl opacity-${opacity73} duration-1000`}
              style={{ fontFamily: 'GmarketSansMedium' }}
            >
              가격동일
            </Typography>
            <div className="gap-8 flex">
              <Link to='https://calendly.com/rememberforerver/10min' target="_blank">
                <IconButton variant="text" color="black" size="sm">
                  <i className="fas fa-calendar-alt text-base z-[999]" />
                </IconButton>
                <Typography
                  variant="small"
                  color="black"
                  className={`mb-12 w-full md:max-w-full lg:max-w-3xl opacity-${opacity73} duration-1000`}
                  style={{ fontFamily: 'GmarketSansMedium' }}
                >
                  예약하기
                </Typography></Link>
              <Link to='http://pf.kakao.com/_Trlxbb' target="_blank">
                <IconButton variant="text" color="black" size="sm">
                  <i className="fa-regular fa-message text-base z-[999]" />
                </IconButton>
                <Typography
                  variant="small"
                  color="black"
                  className={`mb-12 w-full md:max-w-full lg:max-w-3xl opacity-${opacity73} duration-1000`}
                  style={{ fontFamily: 'GmarketSansMedium' }}
                >
                  카톡문의
                </Typography></Link>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
