import React, { useState, useEffect, useRef } from "react";
import {
    Link,
} from "react-router-dom";

import { IconButton, Typography, iconButton } from "@material-tailwind/react";
import {
    motion,
    useScroll,
    useSpring,
    useTransform,
    MotionValue
} from "framer-motion";
import styled, { css } from "styled-components";

const Input = styled.input`
  border: 1px solid #ebebeb;
  width: 100%;
  padding: 12px;
  font-size: 13px;
  border-radius: 5px;
  &:focus {
    outline: none;
    border: 1px solid #ebebeb;
  }
`;

const InputText = (props) => {
    return <Input type={props.type || "text"} {...props}></Input>;
};

const Button = styled.button``;

const DefaultButton = (props) => {
    return (
        <>
            <Button {...props}></Button>
        </>
    );
};
export const getButtonPadding = (size) => {
    let padding;
    switch (size) {
        case "lg":
            padding = 16;
            break;
        case "md":
            padding = 8;
            break;
        case "sm":
            padding = 4;
            break;
        default:
            padding = 8;
            return null;
    }
    return css`
      padding: ${padding}px;
    `;
};
export const getButtonFontSize = (size) => {
    let fontSize;
    switch (size) {
        case "lg":
            fontSize = 15;
            break;
        case "md":
            fontSize = 13;
            break;
        case "sm":
            fontSize = 10;
            break;
        default:
            fontSize = 13;
            return null;
    }
    return css`
      font-size: ${fontSize}px;
    `;
};

export const PrimaryButton = styled(DefaultButton)`
  color: #fff;
  background-color: #157af4;
  ${(props) => getButtonPadding(props.size)}
  ${(props) => getButtonFontSize(props.size)}
  text-align: center;
  border: none;
  width: 100%;
  margin-bottom: 8px;
  font-family: "NanumSquareRound", "Roboto", "sans-serif";
  &:disabled {
    color: #ababab;
    background-color: #ebebeb;
  }
`;
const ButtonGroup = styled.div`
  display: flex;
  gap: 7px;
  flex-direction: column;
  width: 60vw;
`;
const MiniButtonGroup = styled.div`
  display: flex;
  color: #ababab;
  font-size: 13px;
  font-weight: 500;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 120px;
`;
const MiniButton = styled(DefaultButton)``;


const Search = () => {

    const [id, setId] = useState("");
    const [password, setPassword] = useState("");

    return (
        <>
            <div className={`fixed opacity-90 bg-gray-100 bg-opacity-10 h-[60px] top-0 left-0 w-full flex justify-center z-[998]`}>
                <Link to={"/"}>
                    <img
                        alt="evova"
                        className="w-[60px]"
                        src={`${process.env.PUBLIC_URL}/icons/logo-metown_icon_CMYK.webp`}
                    />
                </Link>
            </div>

            <div className="fixed top-0 right-5 z-[998]">
                <Link to={"/"}>
                    <img
                        alt="search"
                        className="h-[30px] mt-[15px]"
                        src={`${process.env.PUBLIC_URL}/icons/search.svg`}
                    />
                </Link>
            </div>

            <div className={`relative min-h-screen w-full `}>
                {/* <div className={`relative min-h-screen w-full `}> */}
                <div className={`absolute inset-0 h-full w-full bg-white transition-all duration-1000`} />
                {/* Section1 */}
                <div className="grid min-h-screen px-8">
                    <div className="container relative z-10 my-auto mx-auto grid place-items-center text-center">
                        <Typography variant="h1" color={`black`} className='duration-1000'>
                            RememberForever
                        </Typography>
                        <Typography variant="h1" color="gray" className='-scale-100 opacity-50' >
                            RememberForever
                        </Typography>
                        <ButtonGroup className="mt-12">
                            <InputText
                                onChange={(e) => setId(e.target.value)}
                                value={id}
                                placeholder="스캔ID"
                            />
                            <PrimaryButton size="lg" disabled={!id}>
                                검색하기
                            </PrimaryButton>
                        </ButtonGroup>

                    </div>
                </div>

            </div>
        </>
    );
};

export default Search;
