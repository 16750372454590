import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router";
import {
    Link,
} from "react-router-dom";

// import heroImage from '../../assets/main-hero.webp'; // 이미지를 불러옵니다
import { IconButton, Typography, iconButton } from "@material-tailwind/react";
import {
    motion,
    useScroll,
    useSpring,
    useTransform,
    MotionValue
} from "framer-motion";

import * as THREE from 'three'; // Ensure THREE is imported to use Vector3
import { Canvas, extend, useThree, useFrame } from "@react-three/fiber";
import { Splat, Float, CameraControls, OrbitControls, StatsGl } from "@react-three/drei";
import { DoubleSide } from "three";
import { Bloom, DepthOfField, EffectComposer, Noise, Vignette } from '@react-three/postprocessing'

function CalcNear({ controlsRef }) {
    const { camera } = useThree(); // This hook gives you access to the Three.js camera

    useFrame(() => {
        // Create a vector representing the origin or any other target point
        const targetPosition = new THREE.Vector3(0, 0, 0);

        // Calculate the distance from the camera to the target position
        const distance = camera.position.distanceTo(targetPosition);

        // Dynamically adjust the near clipping plane based on the distance
        const newNear = Math.max(distance * 0.7, 0.1); // Ensure 'near' is never less than 0.1 to avoid visual artifacts
        // Check if we need to update the camera's near clipping plane
        if (Math.floor(camera.near * 1000) !== Math.floor(newNear * 1000)) {
            camera.near = newNear; // Update the near clipping plane
            camera.updateProjectionMatrix(); // Apply the change
        }
    });

    return null; // This component doesn't render anything itself
}

function Show({ props }) {
    const transY = -1.8;
    const { id } = useParams();
    const { token } = useParams();
    const { scale } = useParams();
    const { R } = useParams();
    const { G } = useParams();
    const { B } = useParams();
    const { rotateY } = useParams(); // degree
    const controlsRef = useRef();
    const threeColor = new THREE.Color(`rgb(${R}, ${G}, ${B})`)
    const height = window.innerHeight;



    return (
        <>
            <div className={`fixed opacity-90 bg-white bg-opacity-10 h-[60px] top-0 left-0 w-full flex justify-center z-[998]`}>
                <Link to={"/"}>
                    <img
                        alt="evova"
                        className="w-[60px]"
                        src={`${process.env.PUBLIC_URL}/icons/logo-metown_icon_CMYK.webp`}
                    />
                </Link>
            </div>
            <div className={`relative w-full `}>
                {/* <div className={`relative min-h-screen w-full `}> */}
                <div className={`absolute inset-0 h-full w-full bg-white transition-all duration-1000`} />
                {/* Section3 */}
                <div className="grid ">
                    <div className={`w-full z-20 center my-0 mx-auto place-items-center text-center opacity-100 duration-1000 bg-slate-200`} style={{ height: `${height}px` }}>
                        <Canvas
                            // dpr={1.5}
                            gl={{ antialias: false }}
                            camera={{ position: [0, 2, -5], fov: 35, near: 3.5 }}
                            style={{ background: `#${threeColor.getHexString()}` }}
                            onCreated={({ gl }) => {
                                gl.toneMapping = false
                                // gl.outputEncoding = THREE.sRGBEncoding
                            }}
                        >
                            <CalcNear
                                controlsRef={controlsRef} />
                            <OrbitControls
                                ref={controlsRef}
                                panSpeed={0.3}
                                maxDistance={7}
                                minDistance={4}
                                maxPolarAngle={(3.141592 / 3) * 1.5}
                                minPolarAngle={(3.141592 / 3) * 0.5}
                                // minAzimuthAngle={0} // Min angle for horizontal rotation
                                enableDamping={true}
                                dampingFactor={0.05}
                                autoRotate={true}
                                autoRotateSpeed={-0}
                                rotateSpeed={1.5}
                            />
                            <Splat
                                // toneMapped
                                alphaTest={0}
                                src={`https://firebasestorage.googleapis.com/v0/b/metowndb.appspot.com/o/remeberforever%2F${id}?alt=media&token=${token}`}
                                position={[-0.0, transY, 0]}
                                rotation={[0, 3.141592 / 180 * rotateY, 0]}
                                scale={scale}
                            />
                            <mesh rotation-x={Math.PI * 0.5} position-y={transY}>
                                <planeGeometry args={[200, 200]} />
                                <meshBasicMaterial color={threeColor.getHex()} side={DoubleSide} />
                            </mesh>

                        </Canvas>
                    </div>
                </div>


            </div>
        </>
    );
};

export default Show;
