import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import logo from './logo.svg';
import './App.css';
import GlobalFont from "./styles/GlobalFont";
import Main from './pages/main/Main'
import Show from './pages/show/Show'
import Search from './pages/search/Search'


export default function App() {

  return (
    <div>

      <head>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css"
          integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        />
        <link rel="icon" href="/image/logo-metown_iconBg_CMKY.webp" />

        <meta content="yes" name="apple-mobile-web-app-capable" />
        <meta content="minimum-scale=1.0, width=device-width, maximum-scale=1, user-scalable=no" name="viewport" />
      </head>
      <GlobalFont />
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/search" element={<Search />} />
          <Route path="/show/:id/:token/:scale/:rotateY/:R/:G/:B" element={<Show />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
